import { UxfPageContext } from "@app-routes";
import { AnalyticsService } from "@shared/services/AnalyticsService";
import { saveMarketingProfile as saveMarketingProfileServer } from "@shared/utils/marketingProfile/saveMarketingProfile";
import { useSaveMarketingProfile } from "@shared/utils/marketingProfile/useSaveMarketingProfile";
import { Cookie } from "@uxf/core/cookie";
import { useOnMount } from "@uxf/core/hooks/useOnMount";
import { createContext, ReactElement, useContext, useEffect } from "react";

const AB_TESTING_VARIANT_COOKIE_NAME = "ab_variant";
const AB_TESTING_VERSION_COOKIE_NAME = "ab_version";
export const AB_TESTING_VARIANT_PROP_NAME = "__AB_TESTING_VARIANT__";
const AB_TESTING_VERSION = "ene_0724_3";
const AB_TESTING_COOKIE_TTL = 3600 * 24 * 365;

function getRandomInt() {
    return Math.floor(Math.random() * 100) + 1;
}

export enum ABTestingVariant {
    A = "A",
    B = "B",
}

interface ABTestingContext {
    variant: ABTestingVariant;
}

const ABTestingContext = createContext<ABTestingContext>({
    variant: ABTestingVariant.A,
});

export const useABTestingVariant = (): ABTestingVariant => {
    const data = useContext(ABTestingContext);

    return data.variant;
};

const getRandomVariant = () => {
    const randomNumber = getRandomInt();
    if (randomNumber > 50) {
        return ABTestingVariant.B;
    }
    return ABTestingVariant.A;
};

export const ABTestingHelper = {
    getVariant: async function (ctx: UxfPageContext<any>): Promise<ABTestingVariant> {
        const cookie = new Cookie(ctx);
        let variant = cookie.get(AB_TESTING_VARIANT_COOKIE_NAME);
        const version = cookie.get(AB_TESTING_VERSION_COOKIE_NAME);

        if ((variant === ABTestingVariant.A || variant === ABTestingVariant.B) && version === AB_TESTING_VERSION) {
            AnalyticsService.setAbTestingVariant(variant, ctx);
            return variant;
        }

        variant = getRandomVariant();
        cookie.set(AB_TESTING_VARIANT_COOKIE_NAME, variant, AB_TESTING_COOKIE_TTL);
        cookie.set(AB_TESTING_VERSION_COOKIE_NAME, AB_TESTING_VERSION, AB_TESTING_COOKIE_TTL);
        AnalyticsService.setAbTestingVariant(variant, ctx);
        await saveMarketingProfileServer(
            {
                eventName: "Varianta ABC",
                eventValueText1: variant,
            },
            ctx.apolloClient,
            ctx,
        );

        return variant as ABTestingVariant;
    },
};

interface ABTestingProviderProps {
    variant: ABTestingVariant | null | undefined;
    children: ReactElement;
}

export function ABTestingProvider(props: ABTestingProviderProps) {
    const saveMarketingProfile = useSaveMarketingProfile();
    const variantFromCookie = Cookie.create().get(AB_TESTING_VARIANT_COOKIE_NAME) as ABTestingVariant | "";
    const randomVariant = getRandomVariant();

    useEffect(() => {
        if (props.variant) {
            AnalyticsService.setAbTestingVariant(props.variant);
        }
    }, [props.variant]);

    useOnMount(() => {
        if (!props.variant && !variantFromCookie) {
            const cookie = Cookie.create();
            AnalyticsService.setAbTestingVariant(randomVariant);
            cookie.set(AB_TESTING_VARIANT_COOKIE_NAME, randomVariant, AB_TESTING_COOKIE_TTL);
            cookie.set(AB_TESTING_VERSION_COOKIE_NAME, AB_TESTING_VERSION, AB_TESTING_COOKIE_TTL);
            saveMarketingProfile({
                eventName: "Varianta ABC",
                eventValueText1: randomVariant,
            });
        }
    });

    return (
        <ABTestingContext.Provider value={{ variant: props.variant || variantFromCookie || randomVariant }}>
            {props.children}
        </ABTestingContext.Provider>
    );
}
